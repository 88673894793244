import moment from 'moment';
import { YW_CORPORATE_TELEPHONE_NUMBERS } from '../settings/urls';

export const createToken = (token) => ({ Authorization: `Bearer ${token}` });

export const formatAccountReferenceForRender = (value) => {
  if (!value) {
    return '';
  }
  const accountReference = `${value}`;
  let formattedRef = '';
  for (let i = 0; i < accountReference.length; i++) {
    if (!(i % 4)) {
      formattedRef += ' ';
    }
    formattedRef += `${accountReference[i]}`;
  }
  return formattedRef;
};

export const formatAddressForRender = (addressData) => {
  let addressAsArray = addressData;

  if (!Array.isArray(addressData)) {
    const { addressLineOne, addressLineTwo, addressLineThree, addressLineFour, postcode } = addressData;
    addressAsArray = [addressLineOne, addressLineTwo, addressLineThree, addressLineFour, postcode];
  }

  const valueFilter = (data) => data && data !== '';
  return addressAsArray.filter(valueFilter).join(', ');
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const formatDate = (dateTime) => {
  const d = new Date(dateTime);
  dateTime = d.toLocaleString('en-GB');
  dateTime = dateTime.split(',')[0];
  return moment(dateTime, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const formatDateToString = (dateTime) => {
  const d = new Date(dateTime);
  dateTime = d.toLocaleString('en-GB');
  dateTime = dateTime.split(',')[0];
  return moment(dateTime, 'DD/MM/YYYY').format('Do MMMM YYYY');
};

export const formatDay = (date) => {
  const sts = [1, 21, 31];
  const nds = [2, 22];
  const rds = [3, 23];

  if (sts.includes(Number(date))) {
    return `${date}st`;
  }

  if (nds.includes(Number(date))) {
    return `${date}nd`;
  }

  if (rds.includes(Number(date))) {
    return `${date}rd`;
  }

  if (date === 'Last') {
    return date;
  }

  return `${date}th`;
};

export const removeNonDigits = (str) => str.replace(/[^0-9]/g, '');

export const uppercaseFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const httpErrorHandler = (error) => {
  if (error.errors && error.errors.length > 0) {
    if (typeof error.errors[0] === 'object' && !Array.isArray(error.errors[0])) {
      return error.errors[0];
    } else {
      return `${error.errors[0]}`;
    }
  } else {
    return error.status ? `${error.status}` : '500';
  }
};

export const isCorporateTelephoneNumbers = (value) => {
  const phoneNumber = value.replace(/ /g, '');
  const YWTelephoneNumbers = Object.values(YW_CORPORATE_TELEPHONE_NUMBERS).map((item) => item.replace(/ /g, ''));

  if (YWTelephoneNumbers.includes(phoneNumber)) {
    return true;
  }

  return false;
};

export const isEligibleFirstName = (value) => {
  if (value <= 1) {
    return false;
  }

  return true;
};

export const temporaryChangesDateValid = (value) => {
  const currentDate = new Date();
  const dateSelected = value.split('-'); // Month - Year
  const updatedDateSelected = new Date();
  updatedDateSelected.setMonth(dateSelected[0] - 1);
  updatedDateSelected.setFullYear(dateSelected[1]);

  return currentDate <= updatedDateSelected;
};

export const getDefaultTemporaryChangesDate = () => {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear() + 2;
  return `${month < 10 ? `0${month}` : month}-${year}`;
};

export const handlePushToDataLayer = ({ event, journey, stepName, state, other }) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      ...(event && { event }), // The event
      form_id: 'online_account_registration', // The form id
      ...(stepName && { form_name: stepName.replace('/', '').replaceAll('-', '_') }), // The name of the step
      ...(state && { form_state: state }), // The state success / failure
      ...other, // Optionally add more properties to the object
    });
  }
};

export const handleNextRoute = (nextRoute, hasCustomerReferenceNumber) => {};
