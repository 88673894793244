import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from 'yw-ui.v2/dist/components';
import { APP_SLUGS, APP_URLS, YW_URLS } from '../../settings/urls';
import { APP_BASENAME } from '../../settings';

const AppBreadcrumbs = ({ path, pathname }) => {
  const getCurrentStepName = () => {
    switch (pathname) {
      case `/${APP_URLS.EXISTING_CUSTOMER}`: {
        return 'Existing customer';
      }
      case `/${APP_URLS.CONFIRM_CRN}`: {
        return 'Customer reference number';
      }
      case `/${APP_URLS.LOGIN_DETAILS}`:
      case `/CRN/${APP_URLS.LOGIN_DETAILS}`: {
        return 'Login details';
      }
      case `/${APP_URLS.CONTACT_DETAILS}`:
      case `/CRN/${APP_URLS.CONTACT_DETAILS}`: {
        return 'Contact details';
      }
      case `/${APP_URLS.SUMMARY}`:
      case `/CRN/${APP_URLS.SUMMARY}`: {
        return 'Summary';
      }
      case `/${APP_URLS.CONFIRMATION}`:
      case `/CRN/${APP_URLS.CONFIRMATION}`: {
        return 'Confirmation';
      }
      case `/${APP_URLS.SOMETHING_WENT_WRONG}`:
      case `/CRN/${APP_URLS.SOMETHING_WENT_WRONG}`: {
        return 'Something went wrong';
      }
      default:
        return '';
    }
  };

  const getCrumbs = () => {
    if (pathname === `${APP_SLUGS.REGISTER}/start`) {
      return [
        {
          label: 'Home',
          to: YW_URLS.HOME,
          isExternal: true,
        },
        {
          label: 'Registration',
          isExternal: false,
        },
      ];
    } else {
      return [
        {
          label: 'Home',
          to: YW_URLS.HOME,
          isExternal: true,
        },
        {
          label: 'Registration',
          isExternal: true,
          to: `${APP_BASENAME}${APP_SLUGS.REGISTER}`,
        },
        {
          label: getCurrentStepName(),
        },
      ];
    }
  };

  return <Breadcrumbs showIcon={true} crumbs={getCrumbs()} addNbsp={false} />;
};

AppBreadcrumbs.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string,
  representative: PropTypes.string,
};

export default AppBreadcrumbs;
