import React from 'react';
import { PropTypes } from 'prop-types';
import { Header, Footer } from 'yw-ui.v2';
import { APPLICATION_NAME, MAIN_URL } from '../../../../common/settings';

function MainLayout({ children }) {
  return (
    <div className="c-app-registration">
      <Header
        id="main-header"
        title={APPLICATION_NAME.REGISTRATION}
        toLink={MAIN_URL}
        logoLink="https://www.yorkshirewater.com/"
        skipToMainContent
      />
      <div className="c-app">{children}</div>
      <Footer id="main-footer" />
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
