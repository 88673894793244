import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import lazyLoader from '../../common/hoc/lazyLoader';
import { APP_URLS } from '../../common/settings/urls';
import { history } from '../../store';
import { scrollToSwoosh, handleFocus } from './../../common/functions';
import { HeroLayout, MainLayout } from './scenes/layouts';
import { AppBreadcrumbs, PageContentHeader } from '../../common/components';
import { formCompletedSelector, formStartedSelector } from './store/app/appSelectors';
import { resetApp } from './store/app/appActions';
import { hasCustomerReferenceNumberSelector } from './store/customer/customerSelectors';
import { HAS_CUSTOMER_REFERENCE_NUMBER } from '../../common/constants/existingCustomer';

const App = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const formStarted = useSelector(formStartedSelector);
  const formCompleted = useSelector(formCompletedSelector);
  const hasCustomerReferenceNumber = useSelector(hasCustomerReferenceNumberSelector);

  const startComponent = lazyLoader(() => import('./scenes/01_start/Start'));
  const existingCustomerComponent = lazyLoader(() => import('./scenes/02_existing_customer/ExistingCustomer'));
  const customerReferenceNumberComponent = lazyLoader(() =>
    import('./scenes/03_customer_reference_number/CustomerReferenceNumber'),
  );
  const LoginDetailsComponent = lazyLoader(() => import('./scenes/04_login_details/LoginDetails'));
  const ContactDetailsComponent = lazyLoader(() => import('./scenes/05_contact_details/ContactDetails'));
  const SummaryComponent = lazyLoader(() => import('./scenes/06_summary/Summary'));
  const ConfirmationComponent = lazyLoader(() => import('./scenes/07_confirmation/Confirmation'));
  const SomethingWentWrongComponent = lazyLoader(() => import('./scenes/08_something_went_wrong/SomethingWentWrong'));

  const getRoutes = (withCRN) => {
    if (withCRN) {
      return [
        { route: APP_URLS.START, component: startComponent },
        { route: APP_URLS.EXISTING_CUSTOMER, component: existingCustomerComponent },
        { route: APP_URLS.CONFIRM_CRN, component: customerReferenceNumberComponent },
        { route: `CRN/${APP_URLS.LOGIN_DETAILS}`, component: LoginDetailsComponent },
        { route: `CRN/${APP_URLS.CONTACT_DETAILS}`, component: ContactDetailsComponent },
        { route: `CRN/${APP_URLS.SUMMARY}`, component: SummaryComponent },
        { route: `CRN/${APP_URLS.CONFIRMATION}`, component: ConfirmationComponent },
        { route: `CRN/${APP_URLS.SOMETHING_WENT_WRONG}`, component: SomethingWentWrongComponent },
      ];
    }
    return [
      { route: APP_URLS.START, component: startComponent },
      { route: APP_URLS.EXISTING_CUSTOMER, component: existingCustomerComponent },
      { route: APP_URLS.CONFIRM_CRN, component: customerReferenceNumberComponent },
      { route: APP_URLS.LOGIN_DETAILS, component: LoginDetailsComponent },
      { route: APP_URLS.CONTACT_DETAILS, component: ContactDetailsComponent },
      { route: APP_URLS.SUMMARY, component: SummaryComponent },
      { route: APP_URLS.CONFIRMATION, component: ConfirmationComponent },
      { route: APP_URLS.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
    ];
  };

  const [routeComponents, setRouteComponents] = useState(getRoutes(false));

  useEffect(() => {
    // Restart the journey if form has not started.
    if (!formStarted) {
      history.push(`/${APP_URLS.START}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStarted]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        // Redirect user to start page if form is submitted and browser's back button is clicked.
        if (formCompleted) {
          handleResetForm();
          history.push(`/${APP_URLS.START}`);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCompleted]);

  const handleNext = (nextRoute, hasCRN = hasCustomerReferenceNumber) => {
    if (hasCRN === HAS_CUSTOMER_REFERENCE_NUMBER.YES) {
      setRouteComponents(getRoutes(true));
      history.push(`/CRN/${nextRoute}`);
    } else {
      setRouteComponents(getRoutes(false));
      history.push(`/${nextRoute}`);
    }
  };

  const handleBack = () => {
    history.goBack();
    scrollToSwoosh();
    handleFocus('#page-heading_heading');
  };

  const handleResetForm = () => {
    dispatch(resetApp());
  };

  const renderComponent = (Component) => (
    <Component
      path={path}
      pathname={pathname}
      formStarted={formStarted}
      formCompleted={formCompleted}
      history={history}
      handleBack={handleBack}
      handleNext={handleNext}
      handleResetForm={handleResetForm}
    />
  );

  return (
    <MainLayout>
      <Switch>
        <HeroLayout heroCollapse={true}>
          <AppBreadcrumbs path={path} pathname={pathname} />
          <PageContentHeader />
          {routeComponents.map((current, index) => (
            <Route
              key={`${current.route}_${index}`}
              path={`/${current.route}`}
              render={() => renderComponent(current.component)}
              exact
            />
          ))}
        </HeroLayout>
      </Switch>
    </MainLayout>
  );
};

export default App;
