import { CUSTOMER_ACTIONS } from './customerActions';

export const INITIAL_STATE = {
  customerReferenceNumber: '',
  postcode: '',
  hasCustomerReferenceNumber: '',
  address: {
    lookupFields: {
      postcode: '',
      buildingNumber: '',
    },
    addresses: [],
    detail: null,
    selectedAddressReference: '',
    isManualAddress: false,
    manualAddress: {
      addressLineOne: '',
      addressLineTwo: '',
      addressLineThree: '',
      addressLineFour: '',
      postcode: '',
    },
  },
  customerContact: {
    title: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    dateOfBirth: '',
    day: '',
    month: '',
    year: '',
  },
  loginDetails: {
    emailAddress: '',
    password: '',
  },
};

export const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_ACTIONS.UPDATE_CUSTOMER_REFERENCE_NUMBER: {
      return {
        ...state,
        customerReferenceNumber: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_POSTCODE: {
      return {
        ...state,
        postcode: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_HAS_CUSTOMER_REFERENCE_NUMBER: {
      return {
        ...state,
        hasCustomerReferenceNumber: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_LOOKUP_FIELDS: {
      return {
        ...state,
        address: {
          ...state.address,
          lookupFields: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_ADDRESSES: {
      return {
        ...state,
        address: {
          ...state.address,
          addresses: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_ADDRESS_DETAIL: {
      return {
        ...state,
        address: {
          ...state.address,
          detail: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_SELECTED_ADDRESS_REFERENCE: {
      return {
        ...state,
        address: {
          ...state.address,
          selectedAddressReference: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_IS_MANUAL_ADDRESS: {
      return {
        ...state,
        address: {
          ...state.address,
          isManualAddress: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_MANUAL_ADDRESS: {
      return {
        ...state,
        address: {
          ...state.address,
          manualAddress: action.payload,
        },
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_CUSTOMER_CONTACT_DETAILS: {
      return {
        ...state,
        customerContact: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_LOGIN_DETAILS: {
      return {
        ...state,
        loginDetails: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
};
