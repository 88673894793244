// API ENDPOINTS
export const BASE_URL = process.env.NODE_ENV === 'production' ? '#{ApiBaseUrl}' : 'https://mydev.yorkswater.com/api/';

// Endpoints
export const API_BASE_URL = `${BASE_URL}/api`;

export const API_ENDPOINTS = {
  CHECK_CUSTOMER_REFERENCE_NUMBER: `${BASE_URL}accounts/check`,
  CHECK_CUSTOMER_EMAIL_ADDRESS: `${BASE_URL}users/precheck`,
  POSTCODE_LOOKUP: `${BASE_URL}property`,
  POSTCODE_ANYWHERE_LOOKUP: `${BASE_URL}addresses/search?postcode=`,
  POSTCODE_ANYWHERE_ADDRESS_DETAILS: `${BASE_URL}addresses`,
  SUBMIT_FORM: `${BASE_URL}Users`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { API_BASE_URL, API_ENDPOINTS, BASE_URL };
