import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { APP_BASENAME } from '../common/settings';

import register from '../modules/register/store';

const createRootReducer = (history) =>
  combineReducers({
    register,
    router: connectRouter(history),
  });

const historyConfig = { basename: APP_BASENAME };
export const history = createBrowserHistory(historyConfig);

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
  );

  return store;
}
