import RestClient from '../../../../common/modules/rest';
import { API } from '../../../../common/settings';

const rest = new RestClient();
const checkCustomerReferenceNumberEndpoint = API.API_ENDPOINTS.CHECK_CUSTOMER_REFERENCE_NUMBER;
const checkCustomerEmailAddressEndpoint = API.API_ENDPOINTS.CHECK_CUSTOMER_EMAIL_ADDRESS;
// const postcodeLookupEndpoint = API.API_ENDPOINTS.POSTCODE_LOOKUP;
const postcodeLookupEndpoint = API.API_ENDPOINTS.POSTCODE_ANYWHERE_LOOKUP;
const addressDetailsEndpoint = API.API_ENDPOINTS.POSTCODE_ANYWHERE_ADDRESS_DETAILS;

export const checkCustomerReferenceNumber = (params) => rest.post(`${checkCustomerReferenceNumberEndpoint}`, params);

export const checkCustomerEmailAddress = (params) => rest.post(`${checkCustomerEmailAddressEndpoint}`, params);

// export const fetchAddresses = (postcode, buildingNumber) =>
//   rest.get(
//     `${postcodeLookupEndpoint}/search?BuildingNameNumber=${
//       buildingNumber ? encodeURIComponent(buildingNumber) : ''
//     }&Postcode=${postcode}`,
//   );

export const fetchAddressesByPostcode = (postcode) => rest.get(`${postcodeLookupEndpoint}${postcode}`);

export const fetchAddressDetails = (id) => rest.get(`${addressDetailsEndpoint}/${id}`);
