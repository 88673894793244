import React, { useEffect, useState } from 'react';

const lazyLoader = (importedComponent) => ({ ...props }) => {
  const [Component, setComponent] = useState(null);
  const isChunkRefreshed = JSON.parse(window.sessionStorage.getItem('retry-chunk-refresh') || 'false');

  useEffect(() => {
    let isMounted = true;
    importedComponent()
      .then((comp) => {
        const C = comp.default;
        if (isMounted) {
          window.sessionStorage.setItem('retry-chunk-refresh', 'false');
          setComponent(<C {...props} />);
        }
      })
      .catch((error) => {
        if (!isChunkRefreshed) {
          window.sessionStorage.setItem('retry-chunk-refresh', 'true');
          window.location.reload();
        }
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Component ? Component : null;
};

export default lazyLoader;
