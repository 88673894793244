import { combineReducers } from 'redux';
import { appReducer as app } from './app/appReducer';
import { customerReducer as customer } from './customer/customerReducer';

const register = combineReducers({
  app,
  customer,
});

export default register;
