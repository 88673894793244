export const envUrl = (developmentUrl, prodUrl) => (process.env.NODE_ENV === 'production' ? prodUrl : developmentUrl);

export const APP_SLUGS = {
  REGISTER: '/register',
};

export const APP_URLS = {
  HOME: '',
  START: 'start',
  EXISTING_CUSTOMER: 'existing-customer',
  CONFIRM_CRN: 'confirm-CRN',
  LOGIN_DETAILS: 'create-login',
  CONTACT_DETAILS: 'contact-details',
  SUMMARY: 'your-summary',
  CONFIRMATION: 'your-confirmation',
  SOMETHING_WENT_WRONG: 'something-went-wrong',
  REDIRECT: '/logged-in/redirect',
  CALLBACK_RESPONSE: '/callback/response',
  FORGOT_PASSWORD_CONFIRMATION: 'confirmation',
};

export const ENDPOINTS = {
  TOKEN: 'connect/token',
};

export const YW_URLS = {
  ACCOUNT_AREA: envUrl('https://mydev.yorkswater.com/account', '#{AppBaseUrl}account'),
  CALLBACK: 'https://synthetix-ec1.com/clients/yorkshirewater/callback/?trigger=YWOnlineAccount',
  CALLBACK_FORM: 'https://www.yorkshirewater.com/contactus#callback',
  CALLBACK_FORM_URL: 'https://www.yorkshirewater.com/get-in-touch/call-back/',
  CLOSE_ACCOUNT: 'https://yorkshirewater.com/moving/closing-account/',
  CODES_OF_PRACTICE: 'https://www.yorkshirewater.com/policies',
  CONTACT_US: 'https://www.yorkshirewater.com/contactus',
  DIRECT_DEBIT: 'https://www.yorkshirewater.com/billing-payments/direct-debits/',
  DIRECT_DEBIT_GUARANTEE: 'https://www.yorkshirewater.com/dd_guarantee',
  EMERGENCY_HOME_COVER: 'https://www.yorkshirewater.com/your-water/emergency-home-cover/',
  FAQ: 'https://yorkshirewater.com/moving/frequently-asked-questions/',
  GET_IN_TOUCH: 'https://www.yorkshirewater.com/get-in-touch',
  HOME: 'https://www.yorkshirewater.com/',
  LOGIN: envUrl('https://mydev.yorkswater.com/account/login', '#{AppBaseUrl}account'),
  MOVING_HOME: 'https://www.yorkshirewater.com/moving/',
  MOVING_HOME_STUB: 'https://my.yorkshirewater.com/moving-home/',
  MOVING_HOME_BILL_ACCOUNT: 'https://www.yorkshirewater.com/bill-account/moving/',
  OPEN_WATER_URL:
    'https://www.open-water.org.uk/for-customers/find-a-retailer/suppliers/english-water-and-wastewater-retailers/',
  PAY_YOUR_BILL: 'https://www.yorkshirewater.com/billing-payments/paying-your-bill/',
  PRIVACY_TERMS: 'https://www.yorkshirewater.com/about-us/our-policies/privacy-policy/',
  SEVERN_TRENT_URL: 'https://www.stwater.co.uk/',
  SUPPLY_AREA: 'https://yorkshirewater.com/moving/are-you-in-our-supply-area/',
  TERMS: 'https://www.yorkshirewater.com/legal',
};

export const YW_CORPORATE_TELEPHONE_NUMBERS = {
  CUSTOMER_SERVICE: '0345 124 2424',
  BILL_PAY: '0345 124 7247',
  EMERGENCY: '0800 573 553',
  SWITCH_BOARD: '01274 691111',
  LOOP_CUSTOMER_MANAGEMENT: '01274 260000',
};
