import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import configureStore, { history } from './store';

import 'yw-ui.v2/dist/css/yw-ui.v2.min.css';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/register" history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
