import axios from 'axios';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};
const RESPONSE_TYPE = 'json';

class RestClient {
  constructor(authIdentifier) {
    this.authIdentifier = authIdentifier;

    const service = axios.create({
      headers: DEFAULT_HEADERS,
      validateStatus: function(status) {
        return status === 200 || status === 201;
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  auth() {
    const authData = this.authIdentifier ? JSON.parse(global.sessionStorage.getItem(this.authIdentifier)) : undefined;
    return authData ? `Bearer ${authData.access_token}` : undefined;
  }

  handleSuccess(response) {
    return new Promise((resolve) => {
      resolve({ data: response.data, status: response.status });
    });
  }

  handleError = ({ response }) => {
    return Promise.reject({
      status: response.status,
      state: false,
      errors: response?.data?.errors ? response.data.errors : [response.status],
    });
  };

  get(path, headers = {}, responseType = null) {
    return this.service.get(path, {
      headers: { Authorization: this.auth(), ...DEFAULT_HEADERS, ...headers },
      responseType: responseType,
    });
  }

  patch(path, payload) {
    return this.service.request({
      data: payload,
      headers: { Authorization: this.auth(), ...DEFAULT_HEADERS },
      method: 'PATCH',
      responseType: RESPONSE_TYPE,
      url: path,
    });
  }

  post(path, payload) {
    return this.service.request({
      data: payload,
      headers: { Authorization: this.auth(), ...DEFAULT_HEADERS },
      method: 'POST',
      responseType: RESPONSE_TYPE,
      url: path,
    });
  }

  delete(path, payload) {
    return this.service.request({
      data: payload,
      headers: { Authorization: this.auth(), ...DEFAULT_HEADERS },
      method: 'DELETE',
      responseType: RESPONSE_TYPE,
      url: path,
    });
  }
}

export default RestClient;
