export const APPLICATION_NAME = {
  REGISTRATION: 'Online Account Registration',
  LOGIN: 'Online Account',
  FORGOT_PASSWORD: 'Online Account',
};
export const MAIN_URL = 'http://www.yorkshirewater.com';
export const AUTH = { IDENTIFIER: null };
export const APP_BASENAME = process.env.NODE_ENV === 'production' ? '#{AppBaseName}' : 'register';

export { default as API } from './api';
export { default as ROUTES } from './routes';
export { default as TELEMETRY } from './telemetry';
