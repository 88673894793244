import { submitForm } from './appService';

export const APP_ACTIONS = {
  UPDATE_LOADING: 'APP_ACTIONS.UPDATE_LOADING',
  UPDATE_FORM_STARTED: 'APP_ACTIONS.UPDATE_FORM_STARTED',
  UPDATE_FORM_COMPLETED: 'APP_ACTIONS.UPDATE_FORM_COMPLETED',
  UPDATE_ERROR: 'APP_ACTIONS.UPDATE_ERROR',
  UPDATE_EXISTING_CUSTOMER: 'APP_ACTIONS.UPDATE_EXISTING_CUSTOMER',
  RESET_APP: 'APP_ACTIONS.RESET_APP',
};

export const updateFormStarted = (value) => ({
  type: APP_ACTIONS.UPDATE_FORM_STARTED,
  payload: value,
});

export const updateFormCompleted = (value) => ({
  type: APP_ACTIONS.UPDATE_FORM_COMPLETED,
  payload: value,
});

export const updateIsLoading = (value) => ({
  type: APP_ACTIONS.UPDATE_LOADING,
  payload: value,
});

export const updateError = (value) => ({
  type: APP_ACTIONS.UPDATE_ERROR,
  payload: value,
});

export const updateExistingCustomer = (value) => ({
  type: APP_ACTIONS.UPDATE_EXISTING_CUSTOMER,
  payload: value,
});

export const resetApp = () => ({
  type: APP_ACTIONS.RESET_APP,
});

export const submitRegistrationForm = (params, responseCallback, errorCallback) => async () => {
  const errorHandler = (error) => {
    errorCallback(error);
  };

  try {
    const response = await submitForm(params);
    response ? responseCallback() : errorHandler(response.status);
  } catch (error) {
    errorHandler(error);
  }
};
