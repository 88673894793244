import { httpErrorHandler } from '../../../../common/functions/helpers';
import {
  checkCustomerEmailAddress,
  checkCustomerReferenceNumber,
  fetchAddressDetails,
  fetchAddressesByPostcode,
} from './customerService';

export const CUSTOMER_ACTIONS = {
  UPDATE_CUSTOMER_REFERENCE_NUMBER: 'CUSTOMER_ACTIONS.UPDATE_CUSTOMER_REFERENCE_NUMBER',
  UPDATE_POSTCODE: 'CUSTOMER_ACTIONS.UPDATE_POSTCODE',
  UPDATE_HAS_CUSTOMER_REFERENCE_NUMBER: 'CUSTOMER_ACTIONS.UPDATE_HAS_CUSTOMER_REFERENCE_NUMBER',
  UPDATE_LOOKUP_FIELDS: 'CUSTOMER_ACTIONS.UPDATE_LOOKUP_FIELDS',
  UPDATE_ADDRESSES: 'CUSTOMER_ACTIONS.UPDATE_ADDRESSES',
  UPDATE_ADDRESS_DETAIL: 'CUSTOMER_ACTIONS.UPDATE_ADDRESS_DETAIL',
  UPDATE_SELECTED_ADDRESS_REFERENCE: 'CUSTOMER_ACTIONS.UPDATE_SELECTED_ADDRESS_REFERENCE',
  UPDATE_IS_MANUAL_ADDRESS: 'CUSTOMER_ACTIONS.UPDATE_IS_MANUAL_ADDRESS',
  UPDATE_MANUAL_ADDRESS: 'CUSTOMER_ACTIONS.UPDATE_MANUAL_ADDRESS',
  UPDATE_CUSTOMER_CONTACT_DETAILS: 'CUSTOMER_ACTIONS.UPDATE_CUSTOMER_CONTACT_DETAILS',
  UPDATE_LOGIN_DETAILS: 'APP_ACTIONS.UPDATE_LOGIN_DETAILS',
  RESET: 'CUSTOMER_ACTIONS.RESET',
};

export const updateCustomerReferenceNumber = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER_REFERENCE_NUMBER,
  payload: value,
});

export const updatePostcode = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_POSTCODE,
  payload: value,
});

export const updateHasCustomerReferenceNumber = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_HAS_CUSTOMER_REFERENCE_NUMBER,
  payload: value,
});

export const updateLokupFields = (values) => ({
  type: CUSTOMER_ACTIONS.UPDATE_LOOKUP_FIELDS,
  payload: values,
});

export const updateAddresses = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_ADDRESSES,
  payload: value,
});

export const updateAddressDetail = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_ADDRESS_DETAIL,
  payload: value,
});

export const updateSelectedAddressReference = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_SELECTED_ADDRESS_REFERENCE,
  payload: value,
});

export const updateIsManualAddress = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_IS_MANUAL_ADDRESS,
  payload: value,
});

export const updateManualAddress = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_MANUAL_ADDRESS,
  payload: value,
});

export const updateCustomerContactDetails = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER_CONTACT_DETAILS,
  payload: value,
});

export const updateLoginDetails = (value) => ({
  type: CUSTOMER_ACTIONS.UPDATE_LOGIN_DETAILS,
  payload: value,
});

export const resetCustomer = () => ({
  type: CUSTOMER_ACTIONS.RESET,
});

export const validateCustomerReferenceNumber = (params, responseCallback, errorCallback) => async () => {
  const successHandler = (response) => {
    responseCallback(response);
  };

  const errorHandler = (error) => {
    errorCallback(error);
  };

  try {
    const response = await checkCustomerReferenceNumber(params);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    errorHandler(error);
  }
};

export const validateCustomerEmailAddress = (params, responseCallback, errorCallback) => async () => {
  const successHandler = (response) => {
    responseCallback(response);
  };

  const errorHandler = (error) => {
    errorCallback(error);
  };

  try {
    const response = await checkCustomerEmailAddress(params);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    errorHandler(error);
  }
};

export const getAddressesByPostcode = (postcode, responseCallback, errorCallback) => async () => {
  const successHandler = (response) => {
    responseCallback(response);
  };

  const errorHandler = (error) => {
    errorCallback(error);
  };

  try {
    const response = await fetchAddressesByPostcode(postcode);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    errorHandler(httpErrorHandler(error));
  }
};

export const getAddressDetails = (id, responseCallback, errorCallback) => async (dispatch) => {
  const successHandler = (response) => {
    if (response.data) {
      responseCallback(response);
    }
  };

  const errorHandler = (error) => {
    errorCallback(error);
  };

  try {
    const response = await fetchAddressDetails(encodeURIComponent(id));
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    if (!error.state) {
      errorHandler(`${error.errors[0]}`);
    } else {
      error.status ? errorHandler(error.status) : errorHandler(500);
    }
  }
};
