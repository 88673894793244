import React, { StrictMode } from 'react';
import { TELEMETRY } from './common/settings';
import useTelemetry from './common/hooks/useTelemetry';
import { history } from './store';

import RegisterApp from './modules/register/App';

import './common/styles/index.scss';

function App() {
  useTelemetry(TELEMETRY.INSTRUMENTATION_KEY, TELEMETRY.IS_ENABLED, history, TELEMETRY.REPLACE_TITLE);

  return (
    <StrictMode>
      <RegisterApp />
    </StrictMode>
  );
}

export default App;
