import React from 'react';
import { ContentGroup, Heading } from 'yw-ui.v2';

const PageContentHeader = () => {
  return (
    <ContentGroup className="u-mt--300">
      <Heading priority={1} size="lg" id="page-heading_heading" className="app-page-title">
        Register for an online account
      </Heading>
      <div className="c-divider" />
    </ContentGroup>
  );
};

export default PageContentHeader;
