import { APP_ACTIONS } from './appActions';

export const INITIAL_STATE = {
  error: '',
  isLoading: false,
  formStarted: false,
  formCompleted: false,
  existingCustomer: '',
  accountDetailsResponse: null,
};

export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_ACTIONS.UPDATE_FORM_STARTED: {
      return {
        ...state,
        formStarted: action.payload,
      };
    }
    case APP_ACTIONS.UPDATE_FORM_COMPLETED: {
      return {
        ...state,
        formCompleted: action.payload,
      };
    }
    case APP_ACTIONS.UPDATE_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case APP_ACTIONS.UPDATE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case APP_ACTIONS.UPDATE_EXISTING_CUSTOMER: {
      return {
        ...state,
        existingCustomer: action.payload,
      };
    }
    case APP_ACTIONS.RESET_APP: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};
